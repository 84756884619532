<template>
    <Modal :title="title" v-model="isShowModal">
        <template v-slot:modal-content>
            <div class="advice">{{ message }}</div>
            <div class="alert-word">1. <span>將手機畫面旋轉至水平</span></div>
            <div class="phone-rotate-img" />
            <div class="alert-word">
                2.
                <span>
                    使用報表上方「表格工具」中的「表格全螢幕」功能，縮放表格大小</span
                >
            </div>

            <div class="table-full-size-img" />
        </template>
        <template v-slot:modal-bottom>
            <Button
                buttonWord="知道了"
                buttonStyle="blue"
                @click="isShowModal = false"
            />
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'

export default {
    name: 'MobileAlertModal',
    emits: ['update:modelValue'],
    components: {
        Modal,
        Button
    },
    props: {
        modelValue: {
            type: Boolean
        }
    },
    methods: {
        turnOffModal: function () {
            this.isShowModal = false
        }
    },
    watch: {
        isShowModal: function () {
            this.$emit('update:modelValue', this.isShowModal)
        },
        modelValue: function () {
            this.isShowModal = this.modelValue
        }
    },
    data() {
        return {
            isShowModal: this.modelValue,
            title: '閱覽提醒',
            message:
                '建議可以使用下面兩種方法，讓您有更足夠的空間查看報表資訊：'
        }
    },
    mounted() {
        if (this.$isMobile() && !this.$isMobileHorizontal()) {
            const body = document.querySelector('body')
            setTimeout(() => {
                this.$addAllClass(body, 'not-scroll')
            }, 500)
        }
    }
}
</script>

<style lang="scss" scoped>
:deep(.modal-title) {
    padding-bottom: 10px;
}

.advice {
    padding-bottom: 10px;
}

.alert-word {
    display: flex;
    font-size: 14px;
    color: $secondary-grey;
}
.phone-rotate-img {
    width: 120px;
    height: 95px;
    margin: 30px auto;
}
.table-full-size-img {
    width: 80%;
    height: 150px;
    margin: 20px auto;
}
</style>
